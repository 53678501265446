<template>
  <div id="app">
    <div class="bg">
      <div class="navb w" v-show="yy">
        <div class="navb-left"></div>
        <el-select
          v-model="value"
          placeholder="select"
          size="small"
          @change="isLanguage"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="navb w" v-show="ay">
        <el-select
          v-model="value"
          placeholder="select"
          size="small"
          @change="isLanguage"
        >
          <el-option
            style="height: 10vh;z-index: 999;"
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="navb-left"></div>
      </div>
      <div class="w">
        <div class="text" v-show="yy">
          Enjoy party with people around the world anytime, anywhere
        </div>
        <div class="text text_a" v-show="ay">
          استمتع بالحفلات مع أشخاص من أنحاء العالم في أي وقت وفي أي مكان
        </div>
        <!-- 地图 -->
        <div class="world" v-show="yy">
          <!-- 连接 -->
          <div class="connection"></div>
          <div class="picture_1">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_2">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_3">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_4">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_5">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
        </div>
        <div class="world_a" v-show="ay">
          <!-- 连接 -->
          <div class="connection"></div>
          <div class="picture_1">
            <div class="photo"></div>
            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_2">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_3">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_4">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
          <div class="picture_5">
            <div class="photo"></div>

            <div class="pulse1"></div>
            <div class="pulse2"></div>
            <div class="pulse3"></div>
          </div>
        </div>
        <!-- 手机盒 -->
        <div class="phone-box-hind" v-show="yy">
          <div class="phone-box">
            <!-- <div class="bg-box"></div> -->
            <!-- 英语 -->
            <el-carousel
              v-show="yy"
              height="38.4vw"
              indicator-position="none"
              arrow="never"
            >
              <el-carousel-item>
                <img src="./assets/1.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/2.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/3.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/4.png" alt="" />
              </el-carousel-item>
            </el-carousel>
            <!-- 啊语 -->
            <el-carousel
              v-show="ay"
              height="38.4vw"
              indicator-position="none"
              arrow="never"
            >
              <el-carousel-item>
                <img src="./assets/1_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/2_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/3_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/4_ar.png" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div class="phone-box-hind_a" v-show="ay">
          <div class="phone-box">
            <!-- <div class="bg-box"></div> -->
            <!-- 英语 -->
            <el-carousel
              v-show="yy"
              height="38.4vw"
              indicator-position="none"
              arrow="never"
            >
              <el-carousel-item>
                <img src="./assets/1.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/2.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/3.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/4.png" alt="" />
              </el-carousel-item>
            </el-carousel>
            <!-- 啊语 -->
            <el-carousel
              v-show="ay"
              height="38.4vw"
              indicator-position="none"
              arrow="never"
            >
              <el-carousel-item>
                <img src="./assets/1_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/2_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/3_ar.png" alt="" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="./assets/4_ar.png" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="bottom-filex">
        <!-- 下载按钮 -->
        <!-- 英语 -->
        <div class="download w" v-show="yy">
          <div class="goodle-paly" @click="download('google')"></div>
          <div class="app-store" @click="download('app')"></div>
          <div class="huawei" @click="download('huawei')"></div>
          <div class="apk" @click="download('apk')"></div>
        </div>
        <div class="download w download_a" v-show="ay">
          <div class="goodle-paly_a" @click="download('google')"></div>
          <div class="app-store_a" @click="download('app')"></div>
          <div class="huawei_a" @click="download('huawei')"></div>
          <div class="apk_a" @click="download('apk')"></div>
        </div>
        <div class="sevice" v-show="yy">
          <p @click="download('service')"> {{ yy ? ' Terms of service ' : ' شروط الخدمة'}} </p>
          <p> | </p>
          <p @click="download('Policy')"> {{ yy ? '  Privacy Policy ' : ' سياسة الخصوصية'}} </p>
        </div>
        
        
        <div class="sevice" v-show="ay">
          <p @click="download('Policy')"> سياسة الخصوصية </p>
          <p> | </p>
          <p @click="download('service')"> شروط الخدمة </p>
        </div>
        
        <span class="version"
          >Copyright ©2018 Link world Team, All Rights Reserved</span
        >
        <div class="contact" v-show="yy">
          <a href="mailto:Youstar.cs@gmail.com">contact us:Youstar.cs@gmail.com</a>
        </div>
        <div class="contact" v-show="ay">
          <a href="mailto:Youstar.cs@gmail.com">>اتصل بنا: Youstar.cs@gmail.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      yy: true,
      ay: false,
      options: [
        {
          value: "1",
          label: "English",
        },
        {
          value: "2",
          label: "العربية",
        },
      ],
      value: "English",
      poster_url: "",
      google: "",
      app: "",
    };
  },
  beforeCreate() {
   if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
     window.location.href = "https://www.youstar.live/mobile/index.html"
   } 
  },
  created() {
    axios("https://api.qmovies.tv/weekly/poster?type=2").then((res) => {
      if (res.data.code == 0) {
        this.poster_url = res.data.data.poster_url;
        let url = res.data.data.address;
        url = url.split(",");
        this.app = url[0];
        this.google = url[1];
      }
    });
  },
  methods: {
    // 语言切换
    isLanguage() {
      if (this.value == 1) {
        this.yy = true;
        this.ay = false;
      }
      if (this.value == 2) {
        this.yy = false;
        this.ay = true;
      }
    },
    download(str) {
      if (str == "google") {
        window.location.href = this.google;
      }
      if (str == "app") {
        window.location.href = this.app;
      }
      if (str == "apk") {
        window.location.href = this.poster_url;
      }
      if (str == "huawei") {
        window.location.href = "https://appgallery.huawei.com/#/app/C103185171";
      }
      if (str == "service") {
        window.location.href = "https://www.youstar.top/about/terms_of_service.html";
      }
      if (str == "Policy") {
        window.location.href = "https://www.youstar.top/youstar/policy/youstarpolicy.html";
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #fffc00;
}
#app {
  width: 100vw;
  height: 100vh;
  background-color: #fffc00;
}
.bg {
  width: 100%;
  height: 77.2vh;
  background-color: #fffc00;
}
.w {
  width: 59.7vw;
  margin: 0 auto;
}
.navb {
  
  display: flex;
  justify-content: space-between;
  padding-top: 3.6vh;
  height: 10.1vh;
}
.el-select .el-input__inner {
  border: none;
  background-color: #fffc00;
}
.navb-left {
  width: 12.2vw;
  height: 5.6vw;
  background: url("./assets/youstar.png") no-repeat;
  background-size: 100% auto;
}
.el-select {
  width: 100px;
}
.bottom-filex {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 28.7vh;
  background-color: #fff;
}
.phone-box-hind {
  position: absolute;
  z-index: 100;
  left: 69.8vw;
  top: 12.5vh;
  width: 17.8vw;
  height: 38.4vw;
  border: 0.7vw solid #000;
  background-color: #000;
  border-radius: 3vw;
  overflow: hidden;
  transform: scale(0.8);
}
.phone-box-hind_a {
  position: absolute;
  z-index: 100;
  right: 69.8vw;
  top: 12.5vh;
  width: 17.8vw;
  height: 38.4vw;
  border: 0.7vw solid #000;
  background-color: #000;
  border-radius: 3vw;
  overflow: hidden;
  transform: scale(0.8);
}
.phone-box {
  margin: 0 auto;
  width: 17.8vw;
  height: 38.4vw;
}
.phone-box .bg-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 18.8vw;
  height: 38.4vw;
  background: url("./assets/border.png") no-repeat;
  background-size: 100% auto;
}
.phone-box img {
  width: 100%;
}
.text {
  margin-top: 2vw;
  font-size: 2.5vw;
  width: 39.2vw;
}
.text_a {
  text-align: end;
  margin-left: 20.5vw;
}
.connection {
  position: absolute;
  left: 5.3vw;
  top: 2.9vh;
  width: 24vw;
  height: 8.2vw;
  background: url("./assets/组8.png") no-repeat;
  background-size: 100% auto;
}
@keyframes pulse1 {
  0% {
  }
  70% {
    /* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
    width: 5vw;
    height: 5vw;
    opacity: 0.6;
  }
  100% {
    width: 6.2vw;
    height: 6.2vw;
    opacity: 0;
  }
}
.picture_1 div[class^="pulse"] {
  /* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.5vw;
  height: 3.5vw;
  background-color: #fff;
  border-radius: 50%;
  animation: pulse1 4.5s linear infinite;
}

@keyframes pulse2 {
  0% {
  }
  70% {
    /* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
    width: 3.8vw;
    height: 3.8vw;
    opacity: 0.6;
  }
  100% {
    width: 4.8vw;
    height: 4.8vw;
    opacity: 0;
  }
}
.picture_2 div[class^="pulse"] {
  /* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.8vw;
  height: 2.8vw;
  background-color: #fff;

  border-radius: 50%;
  animation: pulse2 4.5s linear infinite;
}

@keyframes pulse3 {
  0% {
  }
  70% {
    /* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
    width: 6.9vw;
    height: 6.9vw;
    opacity: 0.6;
  }
  100% {
    width: 8vw;
    height: 8vw;
    opacity: 0;
  }
}
.picture_3 div[class^="pulse"] {
  /* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.8vw;
  height: 4.8vw;
  background-color: #fff;

  border-radius: 50%;
  animation: pulse3 4.5s linear infinite;
}

@keyframes pulse4 {
  0% {
  }
  70% {
    /* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
    width: 5vw;
    height: 5vw;
    opacity: 0.6;
  }
  100% {
    width: 6vw;
    height: 6vw;
    opacity: 0;
  }
}
.picture_4 div[class^="pulse"] {
  /* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.3vw;
  height: 3.3vw;
  background-color: #fff;

  border-radius: 50%;
  animation: pulse4 4.5s linear infinite;
}

@keyframes pulse5 {
  0% {
  }
  70% {
    /* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
    width: 5vw;
    height: 5vw;
    opacity: 0.6;
  }
  100% {
    width: 6vw;
    height: 6vw;
    opacity: 0;
  }
}
.picture_5 div[class^="pulse"] {
  /* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.1vw;
  height: 3.1vw;
  background-color: #fff;
  border-radius: 50%;
  animation: pulse5 4.5s linear infinite;
}
.world div.pulse2 {
  animation-delay: 1.5s;
}

.world_a div.pulse2 {
  animation-delay: 1.5s;
}

.world div.pulse3 {
  animation-delay: 3s;
}

.world_a div.pulse3 {
  animation-delay: 3s;
}

.world {
  position: absolute;
  left: 18.8vw;
  top: 19.2vw;
  /* margin-top: 4.7vw; */
  width: 36.4vw;
  height: 17.8vw;
  background: url("./assets/world.png") no-repeat;
  background-size: 100% auto;
}
.world_a {
  position: absolute;
  top: 19.2vw;
  right: 18.8vw;
  width: 36.4vw;
  height: 17.8vw;
  background: url("./assets/world.png") no-repeat;
  background-size: 100% auto;
}
.picture_1 {
  position: absolute;
  left: 2.9vw;
  top: 2.3vw;
  width: 3.5vw;
  height: 3.5vw;
}
.picture_1 .photo {
  position: relative;
  width: 3.5vw;
  height: 3.5vw;
  z-index: 999;
  background: url("./assets/picture_1.png") no-repeat;
  background-size: 100% auto;
}
.picture_2 {
  position: absolute;
  left: 7.1vw;
  top: 9.7vw;
  width: 2.8vw;
  height: 2.7vw;
}
.picture_2 .photo  {
  position: relative;
  width: 2.8vw;
  height: 2.7vw;
  z-index: 999;
  background: url("./assets/picture_2.png") no-repeat;
  background-size: 100% auto;
}
.picture_3 {
  position: absolute;
  left: 13.1vw;
  top: 7.6vw;
  width: 4.8vw;
  height: 4.8vw;
}
.picture_3 .photo {
  position: relative;
  width: 4.8vw;
  height: 4.8vw;
  z-index: 999;
  background: url("./assets/picture_3.png") no-repeat;
  background-size: 100% auto;
}
.picture_4 {
  position: absolute;
  left: 19.5vw;
  top: 1.2vw;
  width: 3.3vw;
  height: 3.3vw;
}
.picture_4 .photo{
  position: relative;
  width: 3.3vw;
  height: 3.3vw;
  z-index: 999;
  background: url("./assets/picture_4.png") no-repeat;
  background-size: 100% auto;
}
.picture_5 {
  position: absolute;
  left: 28.8vw;
  top: 7.3vw;
  width: 3.1vw;
  height: 3.1vw;
}
.picture_5  .photo{
  position: relative;
  z-index: 999;
  width: 3.1vw;
  height: 3.1vw;
  background: url("./assets/picture_5.png") no-repeat;
  background-size: 100% auto;
}

.download {
  padding-top: 2vw;
}

.download div {
  float: left;
  width: 10.3vw;
  height: 3.2vw;
  cursor: pointer;
}
.download_a div {
  float: right;
}
.app-store {
  margin: 0 2.4vw;
  background: url("./assets/app_store.png") no-repeat;
  background-size: 100% auto;
}
.goodle-paly {
  background: url("./assets/googleplay.png") no-repeat;
  background-size: 100% auto;
}

.apk {
  
  background: url("./assets/apk.png") no-repeat;
  background-size: 100% auto;
}

.huawei {
  margin: 0  2.4vw 0 0;
  background: url("./assets/huaweia.png") no-repeat;
  background-size: 100% auto;
}

.app-store_a {
  margin: 0 2.4vw;
  background: url("./assets/app_store_a.png") no-repeat;
  background-size: 100% auto;
}

.goodle-paly_a {
  background: url("./assets/googleplay_a.png") no-repeat;
  background-size: 100% auto;
}

.apk_a {
  background: url("./assets/apk_a.png") no-repeat;
  background-size: 100% auto;
}

.huawei_a {
  margin: 0 0 0 2.4vw;
  background: url("./assets/huawei.png") no-repeat;
  background-size: 100% auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.sevice {
  width: 100vw;
  list-style:none;
  display: flex;
  font-size: 2vw;
  margin: 10vh 0 0;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sevice > p {
  margin:  0 20px;
}
.sevice > p:hover {
  cursor:pointer;
}
.contact {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #b9b9b9;
  cursor:pointer;
}
a {
  font-size: 0.7vw;
  text-decoration:none;
  color: #b9b9b9;
}
   


.version {
  margin: 3vh 0 1vh;
  width: 100vw;
  text-align: center;
  display: block;
  font-size: 0.7vw;
  color: #b9b9b9;
}
</style>
